.archer-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.archer-selector > div {
  display: flex;
  width: 50%;
}

.archer-selector label {
  width: 100%;
}

.archer-selector .archer {
  width: 50%;
  transition: 0.3s;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.archer-selector .archer:not(.selected) {
  opacity: 0.3;
  transform: scale(0.9);
}

.archer-selector .archer img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  object-position: 0px 0px;
}

.archer-selector .archer:not(.selected):hover {
  opacity: 0.4;
}

.archer-selector .archer.selected img {
  opacity: 1;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
}

@media (min-width: 992px) {
  .archer-selector {
    width: 600px;
    justify-content: center;
  }
  .archer-selector > div {
    flex-direction: column;
    width: 11.1%;
  }
  .archer-selector .archer {
    width: 100%;
  }
  .archer-selector .archer img {
    height: auto;
  }
}
