.green {
  background-color: #4c7723;
}

.blue {
  background-color: #2b5d9e;
}

.pink {
  background-color: #bf6e8b;
}

.orange {
  background-color: #8c4813;
}

.white {
  background-color: #ababab;
}

.yellow {
  background-color: #af9837;
}

.cyan {
  background-color: #33807f;
}

.purple {
  background-color: #5b1a5f;
}

.red {
  background-color: #6d1e17;
}
