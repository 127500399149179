#ongoing-match {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100vh;
  font-family: 'Teko', sans-serif;
}

#ongoing-match .player {
  display: flex;
  flex: 1;
  padding: 0 4rem;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
}

#ongoing-match .player:nth-child(2n + 1) {
  background-color: rgba(0, 0, 0, 0.1);
}
#ongoing-match .player .avatar {
  display: flex;
  align-items: center;
  position: relative;
}
#ongoing-match .player.dead .avatar::after {
  content: 'dead';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 5rem;
  color: rgba(255, 255, 255, 0.7);
  padding-top: 10rem;
}

#ongoing-match .player img.person {
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  height: 230px;
  width: 230px;
  text-align: center;
  vertical-align: middle;
  line-height: 230px;
  transition: 100ms filter ease-in;
}

#ongoing-match .player.dead .avatar {
  animation-name: shiver;
  animation-duration: 100ms;
  animation-direction: normal;
  animation-iteration-count: 3;
}

#ongoing-match .player.dead img.person {
  filter: brightness(33%) blur(2px) grayscale(75%);
  transform: scaleX(0.8) scaleY(0.8);
}

#ongoing-match .player .avatar img.person.error {
  image-rendering: pixelated;
}

#ongoing-match .player .status {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 6rem;
  justify-content: center;
}

#ongoing-match .player .status .arrows {
  display: flex;
  flex: 1;
}

#ongoing-match .player .status .reason {
  display: flex;
  font-size: 3.2rem;
  justify-content: center;
  align-items: center;
  flex: 1;
}

#ongoing-match .player .status .points {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

#ongoing-match .player .status .arrows img {
  width: 100px;
  height: 100px;
  margin-left: -20px;
}

#ongoing-match .player .status .points > div {
  background-color: #292e33;
  color: #5e6064;
  border-radius: 50%;
  font-size: 3.5rem;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ongoing-match .player .status .points.final > div {
  border-radius: 3rem;
  width: 5rem;
  height: 10rem;
}

#ongoing-match .player .status .points > div.point {
  background-color: #fafafa;
  position: relative;
}

#ongoing-match .player .status .points > div.point .overkill {
  position: absolute;
  background-color: #daa520;
  color: white;
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -1rem;
  right: -1rem;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

#ongoing-match .player .summary {
  width: 29rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  font-size: 4.5rem;
  padding: 4rem 0;
}

#ongoing-match .player .summary .nick {
  line-height: 1;
  text-align: center;
  font-weight: 500;
}

#ongoing-match .player .summary .scores {
  display: flex;
  justify-content: space-around;
}

@keyframes shiver {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
}
