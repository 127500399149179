#overview-page {
  font-family: 'Teko', sans-serif;
  font-size: 3rem;
  margin-left: -22rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 80vh 20vh;
  grid-template-areas: 'next-up upcoming' 'branding upcoming';
}

#overview-page h1 {
  font-size: 3.5rem;
}

#overview-page .branding {
  display: flex;
  grid-area: branding;
  padding: 0;
  align-items: center;
}

#overview-page .branding .tournament {
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

#overview-page .branding .tournament .title {
  font-weight: 100;
  font-size: 10.5rem;
  line-height: 7.2rem;
}

#overview-page .branding .tournament .subtitle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 3.5rem;
}

#overview-page .branding .logo {
  margin: 3rem;
}

#overview-page .branding .logo img {
  width: 20vw;
  max-width: 12rem;
}

#overview-page .next-up .player {
  display: flex;
  align-items: center;
  padding: 2.5rem 2rem;
  font-size: 5rem;
}

#overview-page .player .id,
#overview-page .player .nick {
  flex-grow: 1;
}

#overview-page .player .points,
#overview-page .player .matches {
  font-size: 6rem;
}

#overview-page .player .nick,
#overview-page .player .name,
#overview-page .player .points,
#overview-page .player .matches {
  padding-left: 1rem;
}

#overview-page .next-up .player .avatar {
  height: 10rem;
  width: 10rem;
}

#overview-page .next-up .player img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 15%;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.4rem;
}

#overview-page .next-up .player .nick {
  padding-left: 2rem;
  font-size: 6.4rem;
  margin-top: 0.2em; /* fucking Teko baseline bullshit */
  line-height: 1;
}

#overview-page .player:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.2);
}

#overview-page .next-scheduled .player,
#overview-page .in-queue .player {
  padding: 0.5rem 1.5rem;
}

#overview-page .match-info {
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-size: 3.2rem;
  color: rgba(255, 255, 255, 0.5);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'round | starts-in' 'level | eta';
  margin: 1.5rem 0;
}

#overview-page .match-info .level,
#overview-page .match-info .eta {
  font-size: 6.4rem;
  color: rgba(255, 255, 255, 1);
}

#overview-page .next-scheduled {
  grid-area: next-scheduled;
}

#overview-page .in-queue {
  grid-area: in-queue;
}

#overview-page .upcoming {
  padding: 1rem 1rem;
  grid-area: upcoming;
  max-height: 100vh;
  overflow-y: hidden;
  font-size: 4rem;
}

#overview-page .next-up h1 {
  margin: 0 1.5rem 1rem 1.5rem;
  font-size: 4.8rem;
  text-align: center;
}

#overview-page .upcoming h1 {
  margin: 1.5rem;
}

#overview-page .players {
  background-color: #292e33;
}

#overview-page .next-up {
  margin: 1rem 1rem;
}
