#between-matches {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: 'Teko', sans-serif;
}

#between-matches .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#between-matches .header .branding {
  display: flex;
}
#between-matches .header .branding .logo {
  width: 120px;
  height: 120px;
  margin: 3rem;
}

#between-matches .header .current-time {
  font-size: 8rem;
  margin-right: 4rem;
}

#between-matches .header .branding .tournament h1 {
  font-size: 10.5rem;
  font-weight: 400;
  margin-bottom: 0;
}

#between-matches .header .branding .tournament h2 {
  font-size: 3.5rem;
  margin-top: -4rem;
  color: rgba(250, 250, 250, 0.7);
  font-weight: 500;
}

#between-matches .match-info {
  text-align: center;
}

#between-matches .match-info h1 {
  font-size: 8rem;
  font-weight: 500;
}

#between-matches .match-info h2 {
  font-size: 3.5rem;
  color: rgba(250, 250, 250, 0.7);
  font-weight: 500;
}

#between-matches .match-info h3 {
  font-size: 19.2rem;
  font-weight: 400;
}

#between-matches .players {
  display: flex;
  justify-content: space-between;
  margin: 0 8rem 0 8rem;
}

#between-matches .players .avatar-with-ribbon img {
  width: 350px;
  height: 350px;
}

#between-matches .players .avatar-with-ribbon .ribbon {
  font-size: 3rem;
}
