#login-page {
  display: flex;
  flex-direction: column;
  margin: 1em;
  align-items: center;
}

@media (min-width: 992px) {
  #login-page form {
    max-width: 50%;
  }
}
