.quiver-builder {
    display: flex;
    flex-direction: column;
}

.quiver-builder .add-remove {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

}
.quiver-builder > section {
    display: flex;
    gap: 0.4rem;
    align-items: center;

}
.quiver-builder .add-remove button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
}