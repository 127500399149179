.color-picker {
  display: flex;
  justify-content: space-around;
}

.color-picker .color {
  height: 100%;
  flex-grow: 1;
  cursor: pointer;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.color-picker .color + .color {
  margin-left: 1rem;
}
