#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}

@media (min-width: 992px) {
  #app {
    flex-direction: row;
  }
  #app #navigation-bar {
    width: 22rem;
    position: fixed;
  }
  #app > div.content {
    margin-left: 22rem;
    flex-grow: 1;
  }
}
