#admin-page {
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

#admin-page h1 {
  font-size: 3.2rem;
  font-family: 'Teko', sans-serif;
  margin-top: 1em;
  text-align: center;
}

#admin-page .button-list {
  background-color: #292e33;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

#admin-page .button-list .button + .button {
  margin-top: 1rem;
}

#admin-page .tournament-list {
  background-color: #292e33;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

#admin-page .tournament-list a {
  background-color: #36393e;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  font-weight: 700;
  font-size: 2rem;
  align-items: center;
  margin-bottom: 1em;
  padding: 1em;
  position: relative;
  overflow: hidden;
}

#admin-page .tournament-list a img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  object-fit: cover;
  filter: blur(0.2rem);
  transition: 0.5s;
}

#admin-page .tournament-list a div {
  position: relative;
  z-index: 2;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
}

@media (min-width: 992px) {
  #admin-page {
    flex-direction: row;
    flex-wrap: wrap;
  }
  #admin-page .section {
    width: 33%;
    padding: 1rem;
  }
  #admin-page .section:last-child {
    width: 98%;
  }
  #admin-page .tournament-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  #admin-page .tournament-list a {
    width: 48%;
  }
  #admin-page .tournament-list a:hover img {
    filter: none;
    opacity: 0.3;
  }
}
