#pre-tournament h1,
#pre-tournament h2 {
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  text-align: center;
}

#pre-tournament h1 {
  margin-top: 2rem;
  font-size: 9.6rem;
}

#pre-tournament h2 {
  font-size: 3rem;
}

#pre-tournament .user-cloud {
  width: 80%;
  margin: 4rem auto 0 auto;
}

#pre-tournament .user-cloud .person {
  margin: -1.5rem 1rem;
}

#pre-tournament .ribbon {
  font-family: 'Teko', sans-serif;
  font-weight: 400;
  font-size: 4rem;
  width: 33%;
  margin-top: 5rem;
}

#pre-tournament .ribbon .content {
  background-color: rgb(41, 46, 51);
}
