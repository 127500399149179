#rules-page {
  margin: 0 1em;
}

#rules-page h1,
#rules-page h2,
#rules-page h3,
#rules-page h4 {
  font-weight: 700;
  font-family: 'Teko', sans-serif;
  line-height: 1em;
  margin: 1em 0;
  text-align: center;
}

#rules-page h1 {
  font-size: 4.8rem;
}

#rules-page h2 {
  font-size: 3.4rem;
}

#rules-page h3 {
  font-size: 2.4rem;
}

#rules-page h4 {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
  margin-top: 0;
  margin-bottom: 0.3em;
}

#rules-page p {
  margin-bottom: 1em;
}

#rules-page ul {
  margin-bottom: 1em;
}

#rules-page li {
  list-style-type: circle;
  margin-left: 2em;
  line-height: 1.6em;
}

#rules-page .irl-rules > div {
  margin-bottom: 4em;
}

#rules-page .game-mod {
  display: flex;
}

#rules-page .game-mod h3 {
  text-align: left;
  margin-top: 0;
  margin-bottom: 0.3em;
}

#rules-page .game-mod .images {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  #rules-page .irl-rules {
    display: flex;
    justify-content: space-around;
  }
  #rules-page .irl-rules > div {
    width: 30%;
    background-color: #292e33;
    padding: 1em 1.5em;
  }
  #rules-page .tournament-flow {
    width: 45%;
    margin: auto;
    background-color: #292e33;
    padding: 1em 1.5em;
    margin-bottom: 6em;
  }
  #rules-page .game-mods {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  #rules-page .game-mods .section {
    width: 45%;
    background-color: #292e33;
    padding: 1em 1.5em;
    margin-bottom: 2em;
  }
}
