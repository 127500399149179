.tournament-controls {
  display: flex;
  background-color: rgba(255, 0, 0, 0.05);
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

.tournament-controls .button + .button {
  margin-left: 1rem;
}

.tournament-controls .button .icon {
  font-size: 1.6rem;
}
.tournament-controls .button .icon svg {
  width: 100%;
}

@media (min-width: 992px) {
  .tournament-controls .button .icon {
    font-size: 3.2rem;
  }
}
