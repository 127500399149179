#new-tournament-page {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

#new-tournament-page h1,
#new-tournament-page h2,
#new-tournament-page h3,
#new-tournament-page h4 {
  font-family: 'Teko', sans-serif;
  text-align: center;
}

#new-tournament-page h1 {
  font-size: 4.8rem;
}

#new-tournament-page h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

#new-tournament-page h3 {
  font-size: 3.2rem;
}

#new-tournament-page h4 {
  font-size: 2rem;
}

#new-tournament-page a {
  position: relative;
  margin-bottom: 3rem;
  overflow: hidden;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

#new-tournament-page .description {
  flex-basis: 40%;
  position: relative;
  z-index: 1;
  padding: 1em;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
}

#new-tournament-page img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(1px) grayscale(1);
  transition: 0.5s;
}

#new-tournament-page .description.blue {
  background-color: rgba(50, 91, 185, 0.7);
}

#new-tournament-page .description.green {
  background-color: rgba(76, 119, 35, 0.7);
}

#new-tournament-page .description.red {
  background-color: rgba(201, 37, 37, 0.7);
}

@media (min-width: 992px) {
  #new-tournament-page {
    width: 80%;
    margin: 2rem auto;
  }
  #new-tournament-page a {
    display: flex;
    min-height: 16rem;
  }
  #new-tournament-page .description {
    flex-basis: 20%;
  }
  #new-tournament-page img {
    left: 20%;
    width: 80%;
    filter: grayscale(1);
  }
  #new-tournament-page a:hover img {
    filter: none;
  }
}
