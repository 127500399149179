#disable-users-page {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

#disable-users-page .pickers {
  display: flex;
  font-family: 'Teko', sans-serif;
}
#disable-users-page .pickers > div {
  flex: 1;
}

#disable-users-page .pickers > div + div {
  margin-left: 2rem;
}

#disable-users-page h1 {
  font-size: 2rem;
  font-family: 'Teko', sans-serif;
}

@media (min-width: 992px) {
  #disable-users-page .pickers > div + div {
    margin-left: 2rem;
  }

  #disable-users-page h1 {
    font-size: 3rem;
  }

  #disable-users-page h2 {
    font-size: 2rem;
  }
}
