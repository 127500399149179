.personCard {
  display: flex;
  align-items: stretch;
  background-color: rgb(45, 49, 54);
  border-radius: 0.5rem;
  overflow: hidden;
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.avatarImage {
  border-radius: 50%;
  object-fit: cover;
  object-position: center 25%;
  border: 2px solid white;
}

.info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

.nick {
  font-size: 3.5rem;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.dim {
  opacity: 0.6;
}

.dim .nick {
  text-shadow: none;
  color: rgba(255, 255, 255, 0.7);
}
