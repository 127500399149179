#control-page {
  font-family: 'Teko', sans-serif;
  font-size: 3rem;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
}

#control-page > div {
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
}

#control-page .match-title,
#control-page .scheduling-controls,
#control-page .scheduled-at {
  text-align: center;
}

#control-page .scheduling-controls {
  font-size: 1.8rem;
}
#control-page .scheduling-controls .buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0.6rem;
}
#control-page .scheduling-controls .buttons:after {
  content: '';
  width: 30%;
}
#control-page .scheduling-controls .buttons .button {
  width: 30%;
  font-size: 1.4rem;
  margin-top: 1.4rem;
}
#control-page .scheduling-controls,
#control-page .scheduled-at,
#control-page .danger-zone {
  background-color: #292e33;
  margin: 2rem 1rem;
  padding: 1rem 0;
}

#control-page .danger-zone .actions {
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
}

#control-page .god-button button {
  font-size: 5rem;
  margin: 2rem;
}

#control-page .god-button .icon {
  width: 100%;
  flex-direction: column;
}

#control-page .next-up .player {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 2rem;
}

#control-page .next-up .player .id {
  flex-grow: 1;
}

#control-page .next-up .player .name {
  color: rgba(255, 255, 255, 0.7);
}

#control-page .next-up .player .id,
#control-page .next-up .player .points,
#control-page .next-up .player .matches {
  padding-left: 1rem;
}

#control-page .next-up .player .avatar {
  height: 6.4rem;
  width: 6.4rem;
}

#control-page .next-up .player img {
  height: 6.4rem;
  width: 6.4rem;
  object-fit: cover;
  object-position: 50% 15%;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.3rem;
}

#control-page .player:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.2);
}

#control-page .next-scheduled .player,
#control-page .in-queue .player {
  padding: 0.5rem 1.5rem;
}
@media (min-width: 768px) {
  #control-page .next-up .player {
    font-size: 3rem;
  }
}

@media (min-width: 992px) {
  #control-page .next-up {
    background-color: #292e33;
  }

  #control-page .next-up .player {
    padding: 2.5rem 2rem;
    font-size: 5rem;
  }

  #control-page .next-up .player .avatar {
    height: 10rem;
    width: 10rem;
  }

  #control-page .next-up .player img {
    object-fit: cover;
    object-position: 50% 15%;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.4rem;
  }

  #control-page .next-up .player .id {
    padding-left: 2rem;
    font-size: 6.4rem;
    margin-top: 0.2em; /* fucking Teko baseline bullshit */
  }

  #control-page .next-up {
    margin: 1rem 1rem;
  }
}
