#archer-page {
  display: flex;
  flex-wrap: wrap;
  margin: 1em;
  font-family: 'Teko', sans-serif;
  flex-direction: column;
  align-items: stretch;
}

#archer-page .avatar {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

#archer-page .loading,
#archer-page .error {
  width: 100%;
  text-align: center;
}

#archer-page .button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: -1em;
}

#archer-page h1 {
  font-weight: 700;
  line-height: 1em;
  margin: 1.5em 0 0.5em 0;
  text-align: center;
  font-size: 4.8rem;
  flex-grow: 1;
  width: 100%;
}

#archer-page img {
  border-radius: 50%;
  width: 60vw;
  height: 60vw;
}

#archer-page p {
  text-align: center;
}

#archer-page table {
  width: 80vw;
  font-size: 2.5rem;
  margin: auto;
}

#archer-page table tr:nth-child(2n + 1) {
  background-color: rgba(0, 0, 0, 0.2);
}

#archer-page table td {
  padding: 0.25em 0.4em;
}

@media (min-width: 992px) {
  #archer-page img {
    width: 30vw;
    height: 30vw;
  }
  #archer-page table {
    width: 40vw;
  }
  #archer-page .mobile-only {
    display: none;
  }
}
