#create-tournament-page {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

#create-tournament-page h1 {
  font-size: 4.8rem;
  font-family: 'Teko', sans-serif;
  line-height: 1em;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  margin: 1em 0.2em 0 0.2em;
}

#create-tournament-page .color-picker,
#create-tournament-page .field {
  margin-top: 2rem;
}

#create-tournament-page .color-picker {
  height: 5rem;
}

#create-tournament-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#create-tournament-page .field {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#create-tournament-page .field label {
  text-align: center;
  font-size: 2.4rem;
  font-family: 'Teko', sans-serif;
}

#create-tournament-page .field input, #create-tournament-page .field textarea {
  background-color: rgb(40, 46, 50);
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid black;
  padding: 0.5em 1em;
  width: 100%;
}

#create-tournament-page .error {
  text-align: center;
  margin-top: 3rem;
}

#create-tournament-page .button {
  margin: 3rem 0;
  width: 60%;
}

@media (min-width: 992px) {
  #create-tournament-page {
    align-items: center;
  }
  #create-tournament-page .color-picker {
    width: 60%;
  }
  #create-tournament-page form {
    width: 80%;
  }
  #create-tournament-page .field input {
    font-size: 3.2rem;
  }
  #create-tournament-page .button {
    width: auto;
  }
}
