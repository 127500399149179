#game {
  margin-left: -22rem;
  background-color: #0f0;
  display: grid;
  grid-template-columns: 580px 1fr;
  grid-template-rows: 180px 1fr 96px 1fr 77px 75px;
  grid-template-areas:
    'branding .'
    'caster-video .'
    'caster-info .'
    'players-video .'
    'match-info .'
    'match-info status-bar';
  height: 100vh;
  font-family: 'Teko', sans-serif;
  overflow: hidden;
}

#game .branding {
  grid-area: branding;
  display: flex;
  background-color: #1e2124;
}

#game .branding .logo img {
  width: 120px;
  height: 120px;
  margin: 30px;
}

#game .branding .tournament h1 {
  font-size: 10.5rem;
  font-weight: 400;
  margin-bottom: 0;
}

#game .branding .tournament h2 {
  font-size: 3.5rem;
  margin-top: -4rem;
  color: rgba(250, 250, 250, 0.7);
  font-weight: 500;
}

#game .caster-info {
  grid-area: caster-info;
  background-color: #1e2124;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
}

#game .caster-info .caster:not(:last-child)::after {
  content: '&';
  margin: 0 2rem;
  color: rgba(250, 250, 250, 0.7);
  font-size: 2.4rem;
}

#game .match-info {
  grid-area: match-info;
  display: flex;
  background-color: #1e2124;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#game .match-info .title {
  font-size: 3.4rem;
}

#game .match-info .level-and-length {
  font-size: 2.4rem;
  color: rgba(250, 250, 250, 0.7);
}

#game .status-bar {
  grid-area: status-bar;
  display: flex;
  background-color: #1e2124;
}

#game .status-bar .player {
  display: grid;
  height: 75px;
  grid-template-columns: 75px 1fr 6rem;
  grid-template-rows: 3.6rem 1fr;
  grid-template-areas:
    'avatar arrows arrows'
    'avatar nick points';
  font-size: 3.4rem;
  flex: 1;
}
#game .status-bar .player .avatar {
  grid-area: avatar;
  position: relative;
}

#game .status-bar .player.dead .avatar::before {
  content: 'dead';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.7);
  padding-top: 2rem;
  background-color: rgba(0, 0, 0, 0.68);
}

#game .status-bar .player img.person {
  width: 75px;
  height: 75px;
}

#game .status-bar .player .arrows {
  grid-area: arrows;
  display: flex;
  flex-grow: 1;
}
#game .status-bar .player .arrows .arrow {
  margin-right: -1.5rem;
  margin-top: -0.2rem;
}
#game .status-bar .player .arrows .arrow img {
  width: 2.6rem;
  height: 2.6rem;
}

#game .status-bar .player .reason {
  grid-area: arrows;
  text-align: center;
  font-size: 2.5rem;
}

#game .status-bar .player .nick {
  grid-area: nick;
  padding-left: 0.5rem;
}

#game .status-bar .player .points {
  grid-area: points;
  padding-right: 0.5rem;
  font-size: 3rem;
  text-align: right;
}
#game .status-bar .player .points img {
  width: 2rem;
  height: 2rem;
}
