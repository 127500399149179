#end-qualifying-page {
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  font-family: 'Teko', sans-serif;
}

#end-qualifying-page h1 {
  margin-top: 2rem;
  text-align: center;
}

#end-qualifying-page form {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: 3rem;
  padding: 0 4rem;
}

#end-qualifying-page form button {
  margin-top: 4rem;
  font-weight: 400;
}
