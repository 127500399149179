.avatarWithRibbon {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  align-items: center;
}

.avatarWithRibbon img {
  border-radius: 50%;
  width: 60vw;
  height: 60vw;
  object-fit: cover;
  object-position: center 25%;
}
