.user-list,
.user-cloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.1rem;
}
.user-list .person {
  cursor: pointer;
}

.user-list .person {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 96px;
}

.user-list .person img,
.user-cloud img.person,
.user-cloud .placeholder {
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  height: 96px;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.5);
  text-align: center;
  vertical-align: middle;
  line-height: 96px;
  position: relative;
  background-color: rgba(50, 50, 50, 0.1);
}

.user-cloud > * {
  flex-basis: 25%;
  max-width: 48px;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.user-cloud img.person,
.user-cloud .placeholder {
  width: 48px;
  height: 48px;
}

.user-cloud .placeholder {
  background-color: rgba(0, 0, 0, 0.1);
}

.user-list .person.error,
.user-cloud img.person.error {
  image-rendering: pixelated;
}

.user-list .person .name {
  background-color: #406040;
  text-align: center;
  font-family: 'Teko', sans-serif;
  font-weight: 700;
  position: relative;
  top: -1em;
  padding: 0.4em 0 0.1em 0;
  width: 90%;
}

@media (min-width: 992px) {
  .user-list .person {
    width: 100px;
    margin-right: 1rem;
    cursor: pointer;
  }
  .user-cloud > * {
    flex-basis: 12.5%;
    max-width: 96px;
    margin-bottom: 2rem;
    margin-right: 2rem;
  }
  .user-cloud img.person,
  .user-cloud .placeholder {
    width: 96px;
    height: 96px;
    flex-basis: 96px;
  }
}
