.button {
  background-color: #36393e;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2rem;
  display: flex;
  align-items: stretch;
  border: none;
  color: rgba(255, 255, 255, 1);
  padding: 0;
  transition: 500ms;
  position: relative;
}

.button:not(.disabled) {
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.button:not(.disabled):hover {
  background-color: #434343;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
}

.button .icon {
  width: 2.5em;
  padding: 0.5em 0.7em;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.button svg {
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
}

.button p {
  padding: 0.2em 1em;
  display: flex;
  align-items: center;
}

.button .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 1rem;
  font-size: 0.75em;
  background-color: rgba(0, 0, 0, 0.8);
  transition: 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.button:hover .overlay {
  opacity: 1;
}

.button .icon {
  background-color: #325bb9;
}

.button.positive .icon {
  background-color: #19b26b;
}

.button.warning .icon {
  background-color: #c75800;
}

.button.danger .icon {
  background-color: #c92525;
}

.button.disabled .icon {
  background-color: #405054;
}

.button.disabled {
  color: #5e6064;
  background-color: #424549;
}

.button .icon.loading svg {
  animation-name: rotate;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.button .overlay.loading {
  background-color: rgba(0, 0, 0, 0.3);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
