#next-page {
  margin-left: -22rem;
  font-family: 'Teko', sans-serif;
  display: flex;
  flex-direction: column;
}

#next-page .header {
  margin-bottom: 5rem;
}

#next-page .players {
  display: flex;
  padding: 0 3rem;
}

#next-page .players .player {
  flex-basis: 25%;
  padding: 0 3rem;
}

#next-page .players .player h3 {
  font-size: 4rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
}

#next-page .players .player .card {
  margin-bottom: 3rem;
}

#next-page .players .player .stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'overview overview'
    'this-tournament lifetime';
}

#next-page .players .player .stats .overview {
  grid-area: overview;
  margin-bottom: 2rem;
}

#next-page .players .player .stats .this-tournament {
  grid-area: this-tournament;
  margin-right: 0.5rem;
}

#next-page .players .player .stats .lifetime {
  grid-area: lifetime;
}

#next-page .players .player .stats .overview .points {
  text-align: right;
}

#next-page .players .player .stats .lifetime .best-position .times {
  font-size: 3rem;
}

#next-page .players .player .stats .lifetime .best-position .newcomer {
  font-size: 2.5rem;
}
