#register-page {
  display: flex;
  flex-direction: column;
  margin: 0 1em;
  align-items: center;
  padding-bottom: 5rem;
}

#register-page h1 {
  font-weight: 700;
  font-family: 'Teko', sans-serif;
  line-height: 1em;
  margin: 1em 0 0.5em 0;
  text-align: center;
  font-size: 4.8rem;
}

#register-page form {
  display: flex;
  flex-direction: column;
}

#register-page .field {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#register-page label {
  display: block;
  text-align: center;
  font-size: 2.4rem;
  font-family: 'Teko', sans-serif;
  margin-top: 1em;
}

#register-page input {
  display: block;
  margin: auto;
  font-family: 'Lato', sans-serif;
  box-shadow: inset 5px 5px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #292e33;
  border: 1px solid #111119;
  color: white;
  padding: 1rem;
  width: 100%;
}

#register-page input.nick {
  font-size: 3rem;
}

#register-page p.description {
  margin-bottom: 2rem;
  font-family: 'Teko', sans-serif;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

#register-page .error-message {
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

#register-page .archer-selector {
  margin-bottom: 4rem;
}
