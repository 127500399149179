#tournaments-page {
  display: flex;
  flex-wrap: wrap;
  margin: 0 1em;
}

#tournaments-page h1 {
  font-weight: 700;
  font-family: 'Teko', sans-serif;
  line-height: 1em;
  margin: 1em 0;
  text-align: center;
  font-size: 4.8rem;
  flex-grow: 1;
  width: 100%;
}

#tournaments-page .tournament {
  background-color: #292e33;
  margin-bottom: 2em;
}

#tournaments-page .tournament img {
  max-width: 100%;
  object-fit: cover;
}

#tournaments-page .tournament .info {
  text-align: center;
  padding: 1em;
  font-family: 'Teko', sans-serif;
}

#tournaments-page .tournament h2 {
  font-size: 2.4rem;
}

#tournaments-page .tournament h3 {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 992px) {
  #tournaments-page {
    justify-content: space-around;
  }
  #tournaments-page .tournament {
    width: 30%;
  }
}
