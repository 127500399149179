.largeStatsCard {
  display: flex;
  position: relative;
  box-shadow: 0.5rem -0.5rem 0 0;
  border-radius: 0.4rem;
  padding: 1rem 4rem 0 4rem;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.5rem;
  color: rgb(115, 117, 119);
}

.largeStatsCard * {
  color: rgba(255, 255, 255, 0.9);
}

.largeStatsCard .title {
  position: absolute;
  top: -1.4rem;
  left: 0;
  background-color: rgb(30, 33, 36);
  font-size: 2rem;
  font-weight: 500;
  padding-right: 1rem;
}

.largeStatsCard :nth-child(2) {
  font-size: 9rem;
}

.statsCard {
  display: flex;
  flex-direction: column;
  box-shadow: 0.2rem -0.2rem 0 0;
  border-radius: 0.4rem;
  padding: 1rem 1rem;
  justify-content: space-between;
  margin-right: 0.2rem;
  color: rgb(115, 117, 119);
}

.statsCard * {
  color: rgba(255, 255, 255, 0.9);
}

.statsCard .title {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.statsCard .stat {
  margin-bottom: 1rem;
}

.stat {
  font-size: 5rem;
  font-weight: 600;
}

.stat .name {
  font-size: 1.8rem;
  line-height: 1rem;
  font-weight: 400;
  color: rgb(172, 173, 174);
}
