#qualifications-status {
  display: flex;
  flex-direction: column;
  font-family: 'Teko', sans-serif;
  font-size: 2rem;
  line-height: 2rem;
  margin: 2rem 0 0;
}

#qualifications-status .next-match {
  display: flex;
  flex-direction: column;
}

#qualifications-status .next-match .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0 2rem;
}

#qualifications-status .next-match .players,
#qualifications-status .upcoming-match .players,
#qualifications-status .leaderboard .players {
  display: flex;
  flex-direction: column;
}

#qualifications-status .next-match .players .player,
#qualifications-status .upcoming-match .players .player,
#qualifications-status .leaderboard .players .player {
  display: flex;
  padding: 2rem;
}

#qualifications-status .next-match .players .player img.person,
#qualifications-status .upcoming-match .players .player img.person,
#qualifications-status .leaderboard .players .player img.person {
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  height: 64px;
  width: 64px;
  text-align: center;
  vertical-align: middle;
}

#qualifications-status .upcoming-match .players .player img.person,
#qualifications-status .leaderboard .players .player img.person {
  height: 48px;
  width: 48px;
}

#qualifications-status .next-match .players .player .info,
#qualifications-status .upcoming-match .players .player .info,
#qualifications-status .leaderboard .players .player .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
}

#qualifications-status .next-match .players .player .info .points,
#qualifications-status .upcoming-match .players .player .info .points,
#qualifications-status .leaderboard .players .player .info .points {
  color: rgba(255, 255, 255, 0.7);
}

#qualifications-status .next-match .players .player .rank,
#qualifications-status .upcoming-match .players .player .rank,
#qualifications-status .leaderboard .players .player .rank {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#qualifications-status .upcoming-match,
#qualifications-status .leaderboard {
  margin-top: 4rem;
}

#qualifications-status .upcoming-match h2,
#qualifications-status .leaderboard h2 {
  font-size: 2rem;
  font-weight: 400;
  margin: 2rem;
}

#qualifications-status .leaderboard .divider {
  display: flex;
  justify-content: space-around;
  padding: 2rem 0 3rem;
  margin-top: 2rem;
  background-color: rgba(0, 0, 0, 0.2);
}
#qualifications-status .leaderboard .not-qualifying {
  background-color: rgba(0, 0, 0, 0.2);
  padding-bottom: 2rem;
}
#qualifications-status .leaderboard .not-qualifying .players .player {
  opacity: 0.3;
}

#qualifications-status .players .player:nth-child(2n + 1) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  #qualifications-status {
    display: grid;
    grid-template-columns: 1fr 4rem 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'next-match . leaderboard'
      'upcoming-match . leaderboard'
      '. . leaderboard';
    font-size: 3rem;
    line-height: 3rem;
  }
  #qualifications-status .next-match {
    grid-area: next-match;
  }

  #qualifications-status .upcoming-match {
    grid-area: upcoming-match;
  }

  #qualifications-status .leaderboard {
    grid-area: leaderboard;
    margin-top: 0;
  }

  #qualifications-status .upcoming-match h2,
  #qualifications-status .leaderboard h2 {
    font-size: 3rem;
    font-weight: 400;
    margin-top: 0;
  }

  #qualifications-status .next-match .players .player img.person,
  #qualifications-status .upcoming-match .players .player img.person {
    height: 64px;
    width: 64px;
  }

  #qualifications-status .leaderboard .players .player img.person {
    height: 48px;
    width: 48px;
  }
}
