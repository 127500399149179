#congration-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Teko', sans-serif;
  height: 100vh;
  padding: 5rem 0;
}

#congration-page h1 {
  font-size: 8rem;
}

#congration-page h2 {
  font-size: 5rem;
}
#congration-page .avatar-with-ribbon {
  width: 60rem;
}
#congration-page .avatar-with-ribbon .ribbon {
  font-size: 4rem;
  font-weight: 800;
  text-shadow: 4px 4px 1px rgba(0, 0, 0, 0.3);
}
#congration-page .avatar-with-ribbon .image img {
  width: 40rem;
  height: 40rem;
}
