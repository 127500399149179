#playoff-overview-page {
  margin: 0 0 0 -22rem;
  font-family: 'Teko', sans-serif;
  font-size: 3rem;
  height: 100vh;
  overflow: hidden;
}

#playoff-overview-page .brackets {
  display: flex;
  padding: 0 5rem;
}
#playoff-overview-page .brackets > div {
  flex: 4;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#playoff-overview-page .brackets .playoffs {
  display: flex;
  flex-direction: column;
  flex: 5;
}

#playoff-overview-page .brackets .playoffs .match {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

#playoff-overview-page .brackets .playoffs .match .players {
  margin-left: 3rem;
}
#playoff-overview-page .brackets .playoffs .match > .name,
#playoff-overview-page .brackets .last-chance .match > .name,
#playoff-overview-page .brackets .final .match > .name {
  font-size: 4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  position: relative;
  color: rgba(255, 255, 255, 0.4);
}
#playoff-overview-page .brackets .match.highlight > .name {
  color: white;
}

#playoff-overview-page .brackets .match.highlight > .name:before {
  content: '·';
  position: absolute;
  left: -1rem;
}

#playoff-overview-page .brackets .contenders {
  margin-top: 4rem;
}

#playoff-overview-page .brackets .contenders > .name {
  font-size: 4rem;
  margin-left: 4rem;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.7);
}
#playoff-overview-page .brackets .contenders .up-arrow {
  font-size: 3rem;
}

#playoff-overview-page .players {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#playoff-overview-page .players .entry {
  flex: 1;
  display: flex;
  height: 7.3rem;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.3rem;
  overflow: hidden;
  font-size: 4rem;
}

#playoff-overview-page .players .entry.dim {
  opacity: 0.7;
}

#playoff-overview-page .players .entry .rank {
  text-align: center;
  width: 5rem;
}

#playoff-overview-page .players .entry .card {
  flex: 1;
}

#playoff-overview-page .players .entry .card img {
  width: 5rem;
  height: 5rem;
}
#playoff-overview-page .final .players .entry .card img {
  width: 8.8rem;
  height: 8.8rem;
}

#playoff-overview-page .players .entry:not(.dim) .name {
  background-color: rgba(255, 255, 255, 0.05);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

#playoff-overview-page .players .placeholder .name {
  background-color: rgba(255, 255, 255, 0.05);
}
#playoff-overview-page .players .placeholder .avatar {
  background-color: rgba(255, 255, 255, 0.2);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

#playoff-overview-page .players .entry .name {
  flex: 1;
  padding: 0 2rem;
  align-self: stretch;
  display: flex;
  align-items: center;
}
#playoff-overview-page .players .entry .avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.2rem;
  height: 7.2rem;
}

#playoff-overview-page .players .entry .avatar img {
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  border: 2px solid white;
}

#playoff-overview-page .final .players .entry .avatar {
  width: 10.8rem;
  height: 10.8rem;
}

#playoff-overview-page .final .players .entry .avatar img {
  width: 9.6rem;
  height: 9.6rem;
}

#playoff-overview-page .final .players .entry .name {
  font-weight: 500;
  font-size: 4.5rem;
  display: flex;
  justify-content: space-between;
}

#playoff-overview-page .final .players .entry .score {
  font-size: 2.5rem;
  width: 30%;
}

#playoff-overview-page .green {
  background-color: #4c7723;
}

#playoff-overview-page .blue {
  background-color: #2b5d9e;
}

#playoff-overview-page .pink {
  background-color: #bf6e8b;
}

#playoff-overview-page .orange {
  background-color: #8c4813;
}

#playoff-overview-page .white {
  background-color: #ababab;
}

#playoff-overview-page .yellow {
  background-color: #af9837;
}

#playoff-overview-page .cyan {
  background-color: #33807f;
}

#playoff-overview-page .purple {
  background-color: #5b1a5f;
}

#playoff-overview-page .red {
  background-color: #6d1e17;
}
