.header {
  margin: 1rem 5rem 0 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .title h1 {
  font-size: 6rem;
}
.header .title h2 {
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.7);
}

.branding {
  display: flex;
  align-items: center;
}

.branding .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.branding .logo img {
  width: 10rem;
  height: 10rem;
  margin: 3rem;
}

.branding h2 {
  padding-top: 1.4rem;
  font-weight: 200;
  margin-bottom: 0;
  font-size: 9rem;
}
