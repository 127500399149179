.arrow-selector {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    width: 2rem;
    height: 2rem;
}

.arrow-selector img {
    aspect-ratio: auto;
    height: 100%;
}