#autocaster {
  margin-left: -22rem;
  background-color: #0f0;
  display: grid;
  grid-template-columns: 1340px 1fr;
  grid-template-rows: 754px 251px 75px;
  grid-template-areas:
    '. info-area'
    '. .'
    'status-bar .';
  height: 100vh;
  font-family: 'Teko', sans-serif;
  overflow: hidden;
}

#autocaster .info-area {
  grid-area: info-area;
  background-color: #1e2124;
  display: flex;
  font-size: 3.2rem;
  flex-direction: column;
  justify-content: center;
}
#autocaster .info-area .next-match {
  margin: 3rem;
  flex: 1;
}
#autocaster .info-area .next-match h1 {
  font-size: 5rem;
}
#autocaster .info-area .next-match .player img {
  width: 6.4rem;
  height: 6.4rem;
}

#autocaster .info-area .cta {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 5rem;
  padding: 4rem 0;
}

#autocaster .info-area .cta .drunkenfall {
  color: white;
}

#autocaster .status-bar {
  grid-area: status-bar;
  display: flex;
  background-color: #1e2124;
}

#autocaster .status-bar .player {
  display: grid;
  height: 75px;
  grid-template-columns: 75px 1fr 6rem;
  grid-template-rows: 3.6rem 1fr;
  grid-template-areas:
    'avatar arrows arrows'
    'avatar nick points';
  font-size: 3.4rem;
  flex: 1;
}
#autocaster .status-bar .player .avatar {
  grid-area: avatar;
  position: relative;
}

#autocaster .status-bar .player.dead .avatar::before {
  content: 'dead';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.7);
  padding-top: 2rem;
  background-color: rgba(0, 0, 0, 0.68);
}

#autocaster .status-bar .player img.person {
  width: 75px;
  height: 75px;
}

#autocaster .status-bar .player .arrows {
  grid-area: arrows;
  display: flex;
  flex-grow: 1;
}
#autocaster .status-bar .player .arrows .arrow {
  margin-right: -1.5rem;
  margin-top: -0.2rem;
}
#autocaster .status-bar .player .arrows .arrow img {
  width: 2.6rem;
  height: 2.6rem;
}

#autocaster .status-bar .player .reason {
  grid-area: arrows;
  text-align: center;
  font-size: 2.5rem;
}

#autocaster .status-bar .player .nick {
  grid-area: nick;
  padding-left: 0.5rem;
}

#autocaster .status-bar .player .points {
  grid-area: points;
  padding-right: 0.5rem;
  font-size: 3rem;
  text-align: right;
}
#autocaster .status-bar .player .points img {
  width: 2rem;
  height: 2rem;
}
