.ribbon {
  font-size: 2.4rem;
  width: 80%;
  position: relative;
  text-align: center;
  padding: 0.4em 0.4em 0.3em;
  margin: -1.5em auto 1em;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(41, 46, 51);
  color: #fafafa;
}

.ribbon::before {
  content: '';
  position: absolute;
  display: block;
  bottom: -1em;
  border: 1em solid #303035;
  z-index: -1;
  left: -1.4em;
  border-right-width: 1.4em;
  border-left-color: transparent !important;
}

.ribbon .content::before {
  content: '';
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #252529 transparent transparent transparent;
  bottom: -1em;
  left: 0;
  border-width: 1em 0 0 1em;
}

.ribbon .content::after {
  content: '';
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #252529 transparent transparent transparent;
  bottom: -1em;
  right: 0;
  border-width: 1em 1em 0 0;
}

.ribbon::after {
  content: '';
  position: absolute;
  display: block;
  bottom: -1em;
  border: 1em solid #303035;
  z-index: -1;
  right: -1.4em;
  border-left-width: 1.4em;
  border-right-color: transparent !important;
}

.ribbon.green {
  color: #fafafa !important;
  background-color: #4c7723;
}

.ribbon.green:before,
.ribbon.green:after {
  border-color: #395d18;
}

.ribbon.green .content:before,
.ribbon.green .content:after {
  border-color: #234008 transparent transparent transparent;
}

.ribbon.blue {
  color: #fafafa !important;
  background-color: #2b5d9e;
}

.ribbon.blue:before,
.ribbon.blue:after {
  border-color: #325a8e;
}

.ribbon.blue .content:before,
.ribbon.blue .content:after {
  border-color: #1e385a transparent transparent transparent;
}

.ribbon.pink {
  color: #fafafa !important;
  background-color: #bf6e8b;
}

.ribbon.pink:before,
.ribbon.pink:after {
  border-color: #9c5d74;
}

.ribbon.pink .content:before,
.ribbon.pink .content:after {
  border-color: #4a2a36 transparent transparent transparent;
}

.ribbon.orange {
  color: #fafafa !important;
  background-color: #8c4813;
}

.ribbon.orange:before,
.ribbon.orange:after {
  border-color: #6f380c;
}

.ribbon.orange .content:before,
.ribbon.orange .content:after {
  border-color: #482102 transparent transparent transparent;
}

.ribbon.white {
  color: #fafafa !important;
  background-color: #ababab;
}

.ribbon.white:before,
.ribbon.white:after {
  border-color: #8c8c8c;
}

.ribbon.white .content:before,
.ribbon.white .content:after {
  border-color: #4a4a4a transparent transparent transparent;
}

.ribbon.yellow {
  color: #fafafa !important;
  background-color: #af9837;
}

.ribbon.yellow:before,
.ribbon.yellow:after {
  border-color: #6d5c15;
}

.ribbon.yellow .content:before,
.ribbon.yellow .content:after {
  border-color: #3a310e transparent transparent transparent;
}

.ribbon.cyan {
  color: #fafafa !important;
  background-color: #33807f;
}

.ribbon.cyan:before,
.ribbon.cyan:after {
  border-color: #255858;
}

.ribbon.cyan .content:before,
.ribbon.cyan .content:after {
  border-color: #133131 transparent transparent transparent;
}

.ribbon.purple {
  color: #fafafa !important;
  background-color: #5b1a5f;
}

.ribbon.purple:before,
.ribbon.purple:after {
  border-color: #49104c;
}

.ribbon.purple .content:before,
.ribbon.purple .content:after {
  border-color: #310b33 transparent transparent transparent;
}

.ribbon.red {
  color: #fafafa !important;
  background-color: #6d1e17;
}

.ribbon.red:before,
.ribbon.red:after {
  border-color: #501611;
}

.ribbon.red .content:before,
.ribbon.red .content:after {
  border-color: #380d09 transparent transparent transparent;
}
