#home-page {
  display: flex;
  flex-direction: column;
}

#home-page .hero {
  height: 355px;
  position: relative;
  box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  text-align: center;
}

#home-page .hero::before {
  content: '';
  position: absolute;
  background-image: url(../img/hero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%) brightness(20%);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

#home-page .hero .text {
  margin-top: 106px;
  /* This will make it stack on top of the ::before */
  position: relative;
}

#home-page .hero .logo {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home-page .hero .logo img {
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.9));
  width: 66%;
  opacity: 0.2;
}

#home-page .hero .company {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.7);
}

#home-page .hero .drunkenfall {
  margin: 0.1em;
  font-size: 4.6rem;
  font-weight: 700;
  font-family: 'Teko', sans-serif;
  line-height: 1em;
}

#home-page .hero .tagline {
  font-size: 2rem;
  font-weight: 500;
  font-family: 'Teko', sans-serif;
}

#home-page .hero .cta {
  position: relative;
  margin-top: 2.125em;
}

#home-page .hero .description {
  margin-top: 0.8em;
  font-size: 1.4rem;
}

#home-page .button {
  color: #fafafa;
  background-color: #325bb9;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  display: inline-block;
  border: 1px solid #325bb9;
  padding: 0.7em 1.4em;
  text-transform: uppercase;
  font-family: 'Teko', sans-serif;
  font-weight: 700;
  font-size: 1em;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  line-height: 1em;
  vertical-align: baseline;
}

#home-page .about {
  display: flex;
  flex-direction: column;
  margin: 0 5%;
}

#home-page .about h2 {
  font-family: 'Teko', sans-serif;
  font-weight: 700;
  font-size: 2.4rem;
  margin: 1em 0 0.5em 0;
  text-align: center;
}

#home-page .about .video iframe {
  width: 90vw;
  height: 50.625vw;
}

#home-page .about .previous-tournaments {
  display: flex;
  flex-direction: column;
}

#home-page .about .previous-tournaments .error,
#home-page .about .previous-tournaments .loading {
  align-items: center;
  justify-content: center;
}

#home-page .about .previous-tournament,
#home-page .about .previous-tournaments .error,
#home-page .about .previous-tournaments .loading {
  display: flex;
  flex-direction: column;
  background-color: #292e33;
  height: 50.625vw;
  position: relative;
}

#home-page .about .previous-tournament img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#home-page .about .previous-tournament .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 2em 1em 1em 1em;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 1)
  );
}

#home-page .about .previous-tournament .header {
  font-family: 'Teko', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1em;
}

#home-page .about .previous-tournament .date {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
}

#home-page .about .previous-tournaments .cta,
#home-page .about .cta,
#home-page .previous-tournaments .cta {
  margin-top: 2em;
  text-align: center;
}

#home-page .rules,
#home-page .about-us {
  padding: 0 2rem 3rem 2rem;
}

#home-page .rules p,
#home-page .about-us p {
  margin-bottom: 1.5em;
}

#home-page .rules {
  background-color: #292e33;
}

#home-page .rules h2 {
  margin-bottom: 0;
}

#home-page .rules h3 {
  margin: 0 0 1.5em 0;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

@media (min-width: 992px) {
  #home-page .hero .logo img {
    max-width: 50vw;
  }
  #home-page .about {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1% 3%;
    margin: 0;
  }
  #home-page .about > div {
    flex-grow: 1;
    width: 50%;
  }
  #home-page .about .video iframe {
    width: 100%;
    height: 90%;
  }
  #home-page .about .previous-tournaments {
    padding: 0 0 0 3rem;
  }
  #home-page .about .previous-tournaments {
    height: 500px;
  }
  #home-page .about .previous-tournament {
    display: flex;
    flex-direction: row;
    background-color: #292e33;
    height: 400px;
  }
  #home-page .about .previous-tournament img {
    width: 50%;
  }
  #home-page .about .previous-tournament .info {
    position: relative;
    width: 50%;
    padding: 1em;
    background: none;
  }
  #home-page .rules,
  #home-page .about-us {
    margin-top: 7rem;
    padding: 0 3rem 3rem 3rem;
  }
}

@media (max-width: 991px) {
  #home-page .about > div {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
