#navigation-bar {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  background-color: #292e33;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.3);
}

#navigation-bar .logo .oem {
  height: 4.2rem;
  transform: rotate(90deg);
  transition: 0.8s;
}

#navigation-bar :not(.user) a {
  padding: 1.5rem 2rem;
  flex-grow: 1;
  color: rgba(255, 255, 255, 0.6);
}

#navigation-bar a.active {
  background-color: rgba(0, 0, 0, 0.2);
}

#navigation-bar a.active svg {
  color: #20aaaa;
}

#navigation-bar > div {
  width: 50%;
  display: flex;
  order: 1;
}

#navigation-bar .main-routes {
  order: 2;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

#navigation-bar .main-routes a {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 25%;
}

#navigation-bar .user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#navigation-bar .desktop-only {
  display: none;
}

#navigation-bar .user a {
  flex-grow: unset;
  padding: 2rem;
}

a.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 1rem;
}

#navigation-bar .user img {
  border-radius: 50%;
  width: 4.2rem;
  height: 4.2rem;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

@media (min-width: 992px) {
  #navigation-bar {
    height: 100vh;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.3);
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 65rem;
  }
  #navigation-bar > div {
    width: 100%;
  }
  #navigation-bar .logo .oem {
    transform: none;
    width: 50%;
    height: auto;
  }
  #navigation-bar .main-routes {
    order: 1;
    flex-direction: column;
    flex-grow: 1;
    background: none;
    border: none;
    box-shadow: none;
  }
  #navigation-bar :not(.user) a {
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
  }
  #navigation-bar :not(.user,.logo) a svg {
    font-size: 4.2rem;
    line-height: 1em;
    margin-bottom: 1rem;
  }
  #navigation-bar .user {
    padding-top: 2rem;
    align-self: flex-end;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }
  #navigation-bar .desktop-only {
    display: unset;
  }
  #navigation-bar .user img {
    width: 7.2rem;
    height: 7.2rem;
  }
  #navigation-bar .user .profile p {
    text-align: center;
    font-size: 2.4rem;
    font-family: 'Teko', sans-serif;
    font-weight: 500;
    padding: 1rem 0;
  }
  #navigation-bar .user .settings,
  #navigation-bar .user .log-out,
  #navigation-bar .user .log-in {
    font-size: 2.4rem;
    padding: 2.2rem 1rem;
    color: rgba(255, 255, 255, 0.6);
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  #navigation-bar .user .log-out,
  #navigation-bar .user .log-in {
    background: none;
    border: none;
  }
  #navigation-bar .user .log-in {
    font-size: 2rem;
    line-height: 1em;
    margin-bottom: 1rem;
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
  }
  #navigation-bar .profile .green {
    color: #4e9110;
  }
  #navigation-bar .profile .blue {
    color: #4c7cba;
  }
  #navigation-bar .profile .pink {
    color: #e39bb5;
  }
  #navigation-bar .profile .orange {
    color: #c75800;
  }
  #navigation-bar .profile .white {
    color: #fafafa;
  }
  #navigation-bar .profile .yellow {
    color: #efc822;
  }
  #navigation-bar .profile .cyan {
    color: #59c2c1;
  }
  #navigation-bar .profile .purple {
    color: #a66ce5;
  }
  #navigation-bar .profile .red {
    color: #db0f00;
  }
}

.logo:hover .eye-black {
  animation: eye-dart 1s ease forwards;
}

.logo:hover .body {
  transform-origin: 50% 50%;
  animation: move-body 1.3s ease forwards;
}

@keyframes eye-dart {
  0% { transform: translate(0, 0);  }
  33% { transform: translate(10%, 0); }
  66% { transform: translate(-12%, 0); }
}

@keyframes move-body {
  0% { transform: scale(100%, 100%); rotate: 0  }
  33% { transform: scale(90%, 90%); rotate: 360deg }
  66% { transform: scale(110%, 110%); rotate: 720deg}
}
