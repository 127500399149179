.kill-player-button {
    display: flex;
    flex-direction: column;
    background-color: #4a4a4a;
}

.kill-player-button > section {
    display: flex;
    min-height: 6rem;
    padding: 0 0.4rem;
}

.kill-player-button > section > div {
    min-width: 6rem;
}
