.video {
  text-align: center;
}

.video .placeholder.content {
  width: 90vw;
  height: 50.625vw;
}

.video .placeholder {
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.01);
}

@media (min-width: 992px) {
  .video .placeholder.content {
    width: 100%;
    height: 90%;
  }
}
.video .placeholder {
  overflow: hidden;
  position: relative;
}
.video .placeholder::after {
  content: '';
  display: block;
  background-color: rgba(255, 255, 255, 0.01);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  animation: 1.5s loading-placeholder ease-in-out infinite;
}

@keyframes loading-placeholder {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
