#about-page {
  margin: 0 2em;
}

#about-page h1 {
  font-weight: 700;
  font-family: 'Teko', sans-serif;
  line-height: 1em;
  margin: 1em 0;
  text-align: center;
  font-size: 4.8rem;
}

#about-page p {
  margin-bottom: 2em;
}

@media (min-width: 992px) {
  #about-page .texts {
    font-size: 2.5rem;
    width: 25em;
    margin: auto;
  }
}
