#past-tournament-page {
  display: flex;
  flex-direction: column;
}

#past-tournament-page .hero {
  position: relative;
}

#past-tournament-page .hero .image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

#past-tournament-page .hero img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  filter: opacity(0.5);
}

#past-tournament-page .hero .text {
  padding: 3em 1em;
}

#past-tournament-page h1,
#past-tournament-page h2,
#past-tournament-page h3 {
  font-family: 'Teko', sans-serif;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
}

#past-tournament-page h1 {
  font-size: 4.8rem;
}

#past-tournament-page h2 {
  font-size: 4.2rem;
}

#past-tournament-page h3 {
  font-size: 3.2rem;
}

#past-tournament-page p {
  text-align: center;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
}

#past-tournament-page .section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#past-tournament-page .section:nth-child(2n + 1) {
  background-color: rgba(0, 0, 0, 0.1);
}

#past-tournament-page .section.winner,
#past-tournament-page .section.scrubs {
  padding: 2rem;
}

#past-tournament-page .stats {
  width: 60%;
  font-family: 'Teko', sans-serif;
  font-size: 3rem;
}

#past-tournament-page table.players {
  width: 90%;
  font-family: 'Teko', sans-serif;
  font-size: 2.4rem;
}

#past-tournament-page table.players thead {
  background-color: rgba(0, 0, 0, 0.5);
}

#past-tournament-page table.players tr:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.2);
}

#past-tournament-page table.players td {
  padding: 0.2em;
  text-align: center;
}
#past-tournament-page table.players td.rank {
  width: 4rem;
  text-align: right;
}
#past-tournament-page table.players tbody td.rank {
  color: rgba(255, 255, 255, 0.5);
}

#past-tournament-page table.players td.nick {
  padding: 0.2em 0.4em;
  text-align: left;
}

#past-tournament-page table.match-players {
  width: 90%;
  font-family: 'Teko', sans-serif;
  font-size: 2.4rem;
}

#past-tournament-page table.match-players thead {
  background-color: rgba(0, 0, 0, 0.5);
}

#past-tournament-page table.match-players tr:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.2);
}

#past-tournament-page table.match-players td {
  padding: 0.2em;
  text-align: center;
}

#past-tournament-page table.match-players td.nick {
  padding: 0.2em 0.4em;
  width: 50%;
  text-align: left;
}
#past-tournament-page table.match-players td.name {
  text-align: left;
}

@media (min-width: 992px) {
  #past-tournament-page .winner .avatar img {
    width: 40rem;
    height: 40rem;
  }
}
