#game-utils {
    margin: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
#game-utils > section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-basis: calc(33% - 0.12rem);
}
#game-utils > section h1 {
    font-size: 3.2rem;
    font-family: 'Teko', sans-serif;
    margin-top: 1em;
    text-align: center;
}