#qualification-overview-leaderboard-page {
  margin: 0 0 0 -22rem;
  font-family: 'Teko', sans-serif;
  font-size: 3rem;
  height: 100vh;
  background-color: #00ff00;
  overflow: scroll;
}

#qualification-overview-leaderboard-page .leaderboard {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 0 5rem 5rem;
}

#qualification-overview-leaderboard-page .leaderboard .entry {
  display: flex;
  height: 7.3rem;
  align-items: center;
  margin-right: 5rem;
  width: 30%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.3rem;
  overflow: hidden;
  font-size: 4rem;
  margin-bottom: 1rem;
}

#qualification-overview-leaderboard-page .leaderboard .entry.highlight .name {
  background-color: rgba(255, 255, 255, 0.05);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}


#qualification-overview-leaderboard-page .leaderboard .entry .rank {
  text-align: center;
  width: 9rem;
}

#qualification-overview-leaderboard-page .leaderboard .entry .name {
  flex: 1;
  padding: 0 2rem;
  align-self: stretch;
  display: flex;
  align-items: center;
  word-break: break-all;
}
#qualification-overview-leaderboard-page .leaderboard .entry .card {
  flex: 1;
}
#qualification-overview-leaderboard-page .leaderboard .entry .card img {
  width: 6.4rem;
  height: 6.4rem;
}
