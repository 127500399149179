#touchpad-cat-page {
  display: flex;
  flex-direction: column;
  font-family: 'Teko', sans-serif;
  font-size: 2rem;
  line-height: 2rem;
  margin: 2rem 0 0;
}

#touchpad-cat-page .match {
  display: flex;
  flex-direction: column;
}

#touchpad-cat-page .match .players {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'first second'
    'third fourth';
}

#touchpad-cat-page .match h2 {
  grid-area: title;
  font-weight: 400;
  padding: 0 1rem;
  margin-top: 4rem;
}

#touchpad-cat-page .match .item.placeholder {
  min-height: 6rem;
  background-color: rgba(255, 255, 255, 0.05);
}
#touchpad-cat-page .match .item {
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  margin: 0.5rem;
  min-height: 4.8rem;
}

#touchpad-cat-page .match .vs {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
#touchpad-cat-page .match .nick {
  flex: 1;
  display: flex;
  align-items: center;
}

#touchpad-cat-page .match .player .avatar {
  margin-top: 0.6rem;
}

#touchpad-cat-page .match .player img.person {
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

#touchpad-cat-page .match .item:nth-child(4n) {
  grid-area: first;
  clip-path: polygon(0% 0%, 100% 0, 100% 50%, 85% 100%, 0% 100%);
  padding: 0 3rem 0 1rem;
}

#touchpad-cat-page .match .item:nth-child(4n + 1) {
  grid-area: second;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%, 0% 50%);
  flex-direction: row-reverse;
  padding: 0 1rem 0 3rem;
}

#touchpad-cat-page .match .item:nth-child(4n + 2) {
  grid-area: third;
  clip-path: polygon(0% 0%, 85% 0, 100% 50%, 100% 100%, 0% 100%);
  padding: 0 3rem 0 1rem;
}

#touchpad-cat-page .match .item:nth-child(4n + 3) {
  grid-area: fourth;
  clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0 100%, 0% 50%);
  flex-direction: row-reverse;
  padding: 0 1rem 0 3rem;
}
#touchpad-cat-page .match .item:nth-child(4n + 1) .nick,
#touchpad-cat-page .match .item:nth-child(4n + 3) .nick {
  flex-direction: row-reverse;
}

#touchpad-cat-page .match .item .rank {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#touchpad-cat-page .next-match {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

#touchpad-cat-page .next-match .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

#touchpad-cat-page .next-match .players,
#touchpad-cat-page .upcoming-match .players {
  display: flex;
  flex-direction: column;
}

#touchpad-cat-page .next-match .players .player,
#touchpad-cat-page .upcoming-match .players .player,
#touchpad-cat-page .leaderboard .players .player {
  display: flex;
  padding: 2rem 0;
}

#touchpad-cat-page .next-match .players .player img.person,
#touchpad-cat-page .upcoming-match .players .player img.person,
#touchpad-cat-page .leaderboard .players .player img.person {
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  height: 64px;
  width: 64px;
  text-align: center;
  vertical-align: middle;
}

#touchpad-cat-page .upcoming-match .players .player img.person,
#touchpad-cat-page .leaderboard .players .player img.person {
  height: 48px;
  width: 48px;
}

#touchpad-cat-page .next-match .players .player .info,
#touchpad-cat-page .upcoming-match .players .player .info,
#touchpad-cat-page .leaderboard .players .player .info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
}

#touchpad-cat-page .next-match .players .player .info .points,
#touchpad-cat-page .upcoming-match .players .player .info .points,
#touchpad-cat-page .leaderboard .players .player .info .points {
  color: rgba(255, 255, 255, 0.7);
}

#touchpad-cat-page .next-match .players .player .rank,
#touchpad-cat-page .upcoming-match .players .player .rank,
#touchpad-cat-page .leaderboard .players .player .rank {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#touchpad-cat-page .upcoming-match {
  padding: 0 2rem;
}

#touchpad-cat-page .upcoming-match,
#touchpad-cat-page .leaderboard {
  margin-top: 4rem;
}

#touchpad-cat-page .upcoming-match h2,
#touchpad-cat-page .leaderboard h2 {
  font-size: 2rem;
  font-weight: 400;
  margin: 2rem 0;
}

#touchpad-cat-page .leaderboard .divider {
  display: flex;
  justify-content: space-around;
  padding: 2rem 0 3rem;
  margin-top: 2rem;
  background-color: rgba(0, 0, 0, 0.2);
}
#touchpad-cat-page .leaderboard .players .player {
  padding: 2rem;
}
#touchpad-cat-page .leaderboard .not-qualifying {
  background-color: rgba(0, 0, 0, 0.2);
  padding-bottom: 2rem;
}
#touchpad-cat-page .leaderboard .not-qualifying .players .player {
  opacity: 0.3;
}

#touchpad-cat-page .leaderboard .players .player:nth-child(2n + 1) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  #touchpad-cat-page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      'next-match leaderboard'
      'final leaderboard'
      'playoffs leaderboard'
      '. leaderboard';
  }

  #touchpad-cat-page .next-match {
    grid-area: next-match;
  }
  #touchpad-cat-page .final {
    grid-area: final;
  }
  #touchpad-cat-page .playoffs {
    grid-area: playoffs;
  }
  #touchpad-cat-page .leaderboard {
    grid-area: leaderboard;
    margin-top: 0;
  }

  #touchpad-cat-page .leaderboard h2 {
    margin-top: 0;
    padding: 0 2rem;
  }
}
