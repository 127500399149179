.filterable-user-list {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.filterable-user-list .input {
  display: flex;
  justify-content: space-between;
}
.filterable-user-list .input input[type='text'] {
  margin: 0 0.5rem 1.2rem 0;
  border-radius: 0.5rem;
  border: none;
  background-color: hsl(210, 9%, 9%);
  line-height: 1;
  padding: 0.5rem 1rem 0.2rem 1rem;
  color: white;
  flex: 1;
}
.filterable-user-list .input button {
  border: none;
  background: none;
}
.filterable-user-list .user {
  display: flex;
  background-color: #36393e;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.3rem;
  line-height: 1;
  align-items: center;
  text-align: left;
  border: none;
}
.filterable-user-list .user .avatar {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.filterable-user-list .user .avatar img {
  width: 3.2rem;
  height: 3.2rem;
}
.filterable-user-list .user .identifier .nick {
  color: rgba(250, 250, 250, 1);
}
.filterable-user-list .user .identifier .name {
  color: rgba(255, 255, 255, 0.5);
}
@media (min-width: 992px) {
  .filterable-user-list {
    margin-top: 2rem;
  }

  .filterable-user-list .input input[type='text'] {
    font-size: 3rem;
  }

  .filterable-user-list .user {
    font-size: 3rem;
  }

  .filterable-user-list .user .avatar img {
    width: 6.4rem;
    height: 6.4rem;
  }
}
