#scheduled-tournament-page {
  font-family: 'Teko', sans-serif;
  font-size: 3rem;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
}

#scheduled-tournament-page .title h1 {
  font-size: 3.5rem;
  text-align: center;
}

#scheduled-tournament-page .title p {
  font-size: 2rem;
  text-align: center;
}

#scheduled-tournament-page .participation-button-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 3rem 2rem 0 2rem;
}

#scheduled-tournament-page .participation-button-container button {
  margin: 1rem 3rem;
}

#scheduled-tournament-page .participant-count {
  text-align: center;
}

#scheduled-tournament-page .user-cloud {
  margin: 3rem 4rem 0 4rem;
}

#scheduled-tournament-page .user-cloud::after {
  content: '';
  width: 25vw;
}

#scheduled-tournament-page .ribbon-container {
  margin-top: 8rem;
}

@media (min-width: 992px) {
  #scheduled-tournament-page {
    align-items: center;
  }
  #scheduled-tournament-page .title h1 {
    font-size: 8rem;
  }
  #scheduled-tournament-page .title p {
    font-size: 4rem;
  }
  #scheduled-tournament-page .participation-button-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-size: 5rem;
  }

  #scheduled-tournament-page .participation-button-container button {
    font-size: 3rem;
  }
  #scheduled-tournament-page .user-cloud {
    margin: 4rem 10rem;
    max-width: 100rem;
  }

  #scheduled-tournament-page .ribbon-container {
    width: 50vw;
    margin-top: 8rem;
  }
}
