/* TODO(yasa): Ask Magnus about colors*/
.green {
  color: #4e9110;
}

.blue {
  color: #4c7cba;
}

.pink {
  color: #e39bb5;
}

.orange {
  color: #c75800;
}

.white {
  color: #fafafa;
}

.yellow {
  color: #efc822;
}

.cyan {
  color: #59c2c1;
}

.purple {
  color: #a66ce5;
}

.red {
  color: #db0f00;
}
