#qualification-overview-page {
  margin: 0 0 0 -22rem;
  font-family: 'Teko', sans-serif;
  font-size: 3rem;
  height: 100vh;
  overflow: hidden;
}

#qualification-overview-page .leaderboard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 5rem 5rem;
  height: 93rem;
}

#qualification-overview-page .leaderboard .entry {
  display: flex;
  height: 7.3rem;
  align-items: center;
  margin-right: 5rem;
  width: 30%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.3rem;
  overflow: hidden;
  font-size: 4rem;
}

#qualification-overview-page .leaderboard .entry.highlight .name {
  background-color: rgba(255, 255, 255, 0.05);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

#qualification-overview-page .leaderboard .entry:not(.highlight) {
  opacity: 0.7;
}

#qualification-overview-page .leaderboard .entry .rank {
  text-align: center;
  width: 9rem;
}

#qualification-overview-page .leaderboard .entry .name {
  flex: 1;
  padding: 0 2rem;
  align-self: stretch;
  display: flex;
  align-items: center;
  word-break: break-all;
}
#qualification-overview-page .leaderboard .entry .card {
  flex: 1;
}
#qualification-overview-page .leaderboard .entry .card img {
  width: 6.4rem;
  height: 6.4rem;
}
