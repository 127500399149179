.player-selector {
    display: flex;
    flex-direction: column;
    width: 3rem;
}

.player-selector section {
    display: flex;
    flex-wrap: wrap;
    width: 3.2rem;
}

.player-selector button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 1.6rem;
    height: 1.6rem;
}
.player-selector button.active {
    background-color: #5b1a5f;
    color: white;
}